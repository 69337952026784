import React from "react"
import Helmet from "react-helmet"
import SEO from "../../components/seo"
import Layout from "../../components/layout"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'

export default function Careers() {
  return (
    <Layout>
      <SEO 
        title="Jobs and Careers - Compute Software" 
        description="What's it like to work at Compute Software? See open positions and get started on building your career with us."
      />
      <Helmet></Helmet>
      <section className="careers-hero sub-hero centered bg-img jumbotron jumbotron-fluid">
        <div className="container">
          <h1 className="page-title">Careers at Compute Software</h1>
          <p>Join us on our mission to maximize business value from the cloud</p>
        </div>
      </section>
      <section className="join-us sub-general-content bg-gray">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h3 className="section-title">Join Us</h3>
            </div>
          </div>
          <div className="perks row">
            <div className="perk col-lg-6 col-md-6 col-sm-12">
              <h3>Work where you want</h3>
              <p>We’re a remote-first, distributed company. Work from where you feel most comfortable.</p>
            </div>
            <div className="perk col-lg-6 col-md-6 col-sm-12">
              <h3>Small, focused team</h3>
              <p>Our team size allows us to move quickly and deliver value to customers. Everyone at Compute Software plays an important role in the success of the company.</p>
            </div>
            <div className="perk col-lg-6 col-md-6 col-sm-12">
              <h3>Challenging problem space</h3>
              <p>Running optimally on the cloud is a complex problem set. We’re building a platform to give enterprises the power to grab control of the cloud and their business.</p>
            </div>
            <div className="perk col-lg-6 col-md-6 col-sm-12">
              <h3>Work-life balance</h3>
              <p>Having a healthy life outside of Compute Software is important to us. As long as we’re all getting our work done we support flexible work schedules.</p>
            </div>
          </div>
        </div>
      </section>
      <section className="current-openings sub-general-content">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h3 className="section-title">Openings</h3>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="list-group">
                <div className="job-listing list-group-item row">
                  <div className="job-details col-xs- 12 col-sm-8 col-md-8 col-lg-8">
                    <h3>Senior Software Engineer (Clojure / ClojureScript)</h3>
                    <div className="job-meta">       
                      <span className="location"><FontAwesomeIcon icon={faMapMarkerAlt} size="1x" /> Remote</span>     
                    </div>
                  </div>
                  <div className="job-apply col-xs- 12 col-sm-4 col-md-4 col-lg-4">
                    <a href="/careers/senior-software-engineer" className="btn btn-outline-primary">Learn More</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}